

.task {

  border-bottom: 2px solid #f2f2f2;

  &:last-of-type {
    //border-bottom: none;
  }

}

.text-done {

  color: #333;

}
.task.pt-3.pb-3 {
  position: relative;
}

.task.pt-3.pb-3 .checkIt {
  position: absolute;
  right: 5px;
  top: 20px;
}
table.table-sm td{
  white-space: normal;
  font-size: 14px;
}
table.table.table-striped.table-values.table-sm.mb-4 tbody td:last-child {
  width: 32px !important;
}
table.table-sm th{
  padding: 0.25rem !important;
}
table.table-sm span.btn{
  padding: 5px 5px;
}
.btn-disabled {
  background: #eee;
  color: #333;
  &:hover{
    border-color: #eee;
  }
  &.bagde{
    padding: 2px 8px;
  }
}
