.appView, .modal-dialog {
	&.purpleView {
		.btn-outline-primary {
			--bs-btn-color: #6f42c1;
			--bs-btn-border-color: #6f42c1;
			--bs-btn-hover-bg: #6f42c1;
			--bs-btn-hover-border-color: #6f42c1;
			--bs-btn-active-bg: #6f42c1;
		}
		.btn-primary {
			--bs-btn-bg: #6f42c1;
			--bs-btn-border-color: #6f42c1;
			--bs-btn-hover-bg: #BBA9DC;
			--bs-btn-hover-border-color: #BBA9DC;
		}
		hr {
			background: $purple;
		}
		textarea, input, select{
			&:focus {
				border-color: $purple;
			}
		}
		.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
			background-color: $purple;
		}
		.nice-select:active, .nice-select.open, .nice-select:focus, .nice-select:hover {
			border-color: $purple;
		}
		ul.tabNav {
			li {
				border-bottom-color: $purple1;
				&.active {
					border-bottom-color: $purple;
					color: $purple;
				}
				&:hover {
					color: $purple;
				}
			}
		}
		.card-header-inline {
			background: $purple2;
		}
		ul li::marker {
			color: $purple;
		}
		label.color {
			color: $purple1;
			display: block;
		}
		.infoRow {
			span i {
				cursor: pointer;
			}
			span:hover i {
				color: $purple;
			}
		}
		div.multiselect.isMultiselect.small .multiselect__tags .multiselect__tags-wrap .multiselect__tag {
			background: $purple;
			.multiselect__tag-icon {
				top: -2px;
				&:after {
					color: $light;
				}
			}
		}
		div.multiselect.small .multiselect__single{
			color: $purple;
		}
		div.multiselect {
			.multiselect__select {
				border-color: $purple;
				&:before{
					border-color: $purple transparent transparent transparent;
				}
			}
			&.multiselect--active {
				.multiselect__select {
					background: $purple;
					&:before{
					border-color: $light transparent transparent transparent;
					}
				}
				.multiselect__content-wrapper{
					border-color: $purple1;
					.multiselect__option--highlight{
						background: $purple2;
					}
				}

			}
		}
		div.multiselect .multiselect__tags {
			border-color: $purple1;
		}
		.dp__main.small .dp__pointer.dp__input, .dp__main .dp__pointer.dp__input {
			color: $purple;
			border: 1px solid $purple1;
		}
		.dp__main.small svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main.small svg.dp__icon.dp__input_icon.dp__input_icons,
		.dp__main svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main svg.dp__icon.dp__input_icon.dp__input_icons {
			color: $purple;
			border: 1px solid $purple;
		}
		hr {
			background: $purple1;
		}
		.lineitem.last.ml-auto:hover i {
			color: $purple;
		}
		.page-item:not(.disabled) .page-link {
			color: $purple;
		}
		.fileList i:hover {
			color: $purple;
		}
		b.color {
			color: $purple !important;
		}
		.alert {
			&.alert-message, &.alert-info {
				border: 2px solid $purple;
				.close {
					border: 1px solid $purple;
					color: $purple;
				}
			}
		}
	}
}

label.color {
	color: $blue1;
	display: block;
}

.form-control {
	&.purple {
		+ .input-group-append .btn-outline-primary {
			--bs-btn-color: #6f42c1;
			--bs-btn-border-color: #6f42c1;
			--bs-btn-hover-bg: #6f42c1;
			--bs-btn-hover-border-color: #6f42c1;
			--bs-btn-active-bg: #6f42c1;
		}
	}
}



.btn-outline-primary {
	--bs-btn-color: #0088CC;
	--bs-btn-border-color: #0088CC;
	--bs-btn-hover-bg: #0088CC;
	--bs-btn-hover-border-color: #0088CC;
	--bs-btn-active-bg: #0088CC;
	--bs-btn-active-border-color: #0088CC;
	--bs-btn-active-color: #fff;
	--bs-btn-hover-color: #fff;
}

.btn-outline-primary.purple {
	--bs-btn-color: #6f42c1;
	--bs-btn-border-color: #6f42c1;
	--bs-btn-hover-bg: #6f42c1;
	--bs-btn-hover-border-color: #6f42c1;
	--bs-btn-active-bg: #6f42c1;
	--bs-btn-active-color: #fff;
	--bs-btn-hover-color: #fff;
}

.wrapper {
	span.btn-logout:hover {
		i {
			color: $blue;
		}
	}
	p.color {
		color: $blue1;
	}
	#filter_search {
		background: $light;
		border-color: $blue;
	}
	// Hook subviews here
	&.Tasks, &.TaskDetails, &.drafts, &.DraftDetails, &.Events, &.EventDetails, &.EventTabs, &.TasksTabs {
		.btn-outline-primary {
			--bs-btn-color: #6f42c1;
			--bs-btn-border-color: #6f42c1;
			--bs-btn-hover-bg: #6f42c1;
			--bs-btn-hover-border-color: #6f42c1;
			--bs-btn-active-bg: #6f42c1;
			--bs-btn-active-color: #fff;
			--bs-btn-hover-color: #fff;
		}
		.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
			background-color: $purple;
		}
		.btn-primary {
			--bs-btn-bg: #6f42c1;
			--bs-btn-border-color: #6f42c1;
			--bs-btn-hover-bg: #BBA9DC;
			--bs-btn-hover-border-color: #BBA9DC;
		}
		.form-control:focus, .form-select:focus {
			border-color: #6f42c1;
		}
		span.btn-logout:hover {
			i {
				color: $purple;
			}
		}
		.nice-select:active, .nice-select.open, .nice-select:focus, .nice-select:hover {
			border-color: $purple;
		}
		p.color {
			color: $purple1;
		}
		#filter_search {
			border-color: $purple;
		}
		.dp__main.small .dp__pointer.dp__input {
			color: $purple;
			border: 1px solid $purple1;
		}
		.dp__main.small svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main.small svg.dp__icon.dp__input_icon.dp__input_icons {
			color: $purple;
			border: 1px solid $purple;
		}
		.topMainIcons .actionIcon.double {
			background: $purple1;
		}
		.topMainIcons .actionIcon.double span.active {
			background: $purple;
		}
		.topMainIcons .actionIcon.outter .btn i {
			color: $purple;
		}
		div.multiselect .multiselect__select {
			border-color: $purple;
		}
		div.multiselect.multiselect--active .multiselect__select {
			background: $purple;
			&:before {
				border-color: $light transparent transparent transparent;
			}
		}
		div.multiselect.small .multiselect__single {
			color: $purple;
		}
		div.multiselect .multiselect__content-wrapper {
			border-color: $purple;
		}
		div.multiselect .multiselect__option--highlight {
			background: $purple2;
		}
		div.multiselect .multiselect__select:before {
			border-color: $purple transparent transparent transparent;
		}
		div.multiselect .multiselect__tags {
			border-color: $purple1;
		}
		div.multiselect.multiselect--active .multiselect__tags {
			border-color: $purple;
		}
		hr {
			background-color: $purple1;
		}
		.navbarTop .input-group-append .btn {
			border-right-color: $purple;
		}
		.lineitem.last.ml-auto:hover i {
			color: $purple;
		}
	}
}

.accountingWrapper, .appView.redView, .wrapper.StatsOverview, .wrapper.Legals, .wrapper.StatsType {
	.btn-outline-primary {
		--bs-btn-color: #b21f28;
		--bs-btn-border-color: #b21f28;
		--bs-btn-hover-bg: #b21f28;
		--bs-btn-hover-border-color: #b21f28;
		--bs-btn-active-bg: #b21f28;
		--bs-btn-active-color: #fff;
		--bs-btn-hover-color: #fff;
	}
	.btn-primary {
		--bs-btn-bg: #b21f28;
		--bs-btn-border-color: #b21f28;
		--bs-btn-hover-bg: #E0A5A9;
		--bs-btn-hover-border-color: #E0A5A9;
	}
	.form-control:focus, .form-select:focus {
		border-color: #b21f28;
	}
	.nice-select:active, .nice-select.open, .nice-select:focus, .nice-select:hover {
		border-color: $red;
	}
	.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
		background-color: $red;
	}
	ul.tabNav {
		li {
			border-bottom-color: $red1;
			&.active {
				border-bottom-color: $red;
				color: $red;
			}
			&:hover {
				color: $red;
			}
		}
	}
	.card-header-inline {
		background: $red1;
	}
	ul li::marker {
		color: $red;
	}
	label.color {
		color: $red1;
		display: block;
	}
	.infoRow {
		span i {
			cursor: pointer;
		}
		span:hover i {
			color: $red;
		}
	}
	div.multiselect.isMultiselect.small .multiselect__tags .multiselect__tags-wrap .multiselect__tag {
		background: $red;
		.multiselect__tag-icon {
			top: -2px;
			&:after {
				color: $light;
			}
		}
	}
	div.multiselect {
		.multiselect__select {
			border-color: $red;
		}
		&.multiselect--active {
			.multiselect__select {
				background: $red;
			}
		}
	}
	div.multiselect .multiselect__tags {
		border-color: $red1;
	}
	.dp__main.small .dp__pointer.dp__input {
		color: $red;
		border: 1px solid $red1;
	}
	.dp__main.small svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main svg.dp__icon.dp__input_icon.dp__input_icons, .dp__main.small svg.dp__icon.dp__input_icon.dp__input_icons {
		color: $red;
		border: 1px solid $red;
	}
	hr {
		background: $red1;
	}
	.lineitem.last.ml-auto:hover i {
		color: $red;
	}
	.page-item:not(.disabled) .page-link {
		color: $red;
	}
	.fileList i:hover {
		color: $red;
	}
	b.color {
		color: $red !important;
	}
	.loadFullWrap {
		i {
			color: $red;
		}
	}
	.btn-outline-primary {
		--bs-btn-color: #b21f28;
		--bs-btn-border-color: #b21f28;
		--bs-btn-hover-bg: #b21f28;
		--bs-btn-hover-border-color: #b21f28;
	}
	span.btn-logout:hover {
		i {
			color: $red;
		}
	}
	p.color {
		color: $red1;
	}
	#filter_search {
		border-color: $red;
	}
	.topMainIcons .actionIcon.double {
		background: $red1;
	}
	.topMainIcons .actionIcon.double span.active {
		background: $red;
	}
	.topMainIcons .actionIcon.outter .btn i {
		color: $red;
	}
	div.multiselect .multiselect__select {
		border-color: $red;
	}
	div.multiselect.multiselect--active .multiselect__select {
		background: $red;
		&:before {
			border-color: $light transparent transparent transparent;
		}
	}
	div.multiselect.small .multiselect__single {
		color: $red;
	}
	div.multiselect .multiselect__content-wrapper {
		border-color: $red;
	}
	div.multiselect .multiselect__option--highlight {
		background: $red1;
	}
	div.multiselect .multiselect__select:before {
		border-color: $red transparent transparent transparent;
	}
	div.multiselect .multiselect__tags {
		border-color: $red1;
	}
	div.multiselect.multiselect--active .multiselect__tags {
		border-color: $red;
	}
	hr {
		background-color: $red1;
	}
	.navbarTop .input-group-append .btn {
		border-right-color: $red;
	}
	.lineitem.last.ml-auto:hover i {
		color: $red;
	}
}

.appView.greenView, .wrapper.employees, .wrapper.EmployeeDetails, .modal-dialog.greenView {
	.btn-outline-primary {
		--bs-btn-color: #3B8130;
		--bs-btn-border-color: #3B8130;
		--bs-btn-hover-bg: #3B8130;
		--bs-btn-hover-border-color: #3B8130;
		--bs-btn-active-bg: #3B8130;
		--bs-btn-active-border-color: #3B8130;
		--bs-btn-active-color: #fff;
		--bs-btn-hover-color: #fff;
	}
	.form-control:focus, .form-select:focus {
		border-color: #3B8130;
	}
	.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
		background-color: $green;
	}
	.nice-select:active, .nice-select.open, .nice-select:focus, .nice-select:hover {
		border-color: $green;
	}
	.btn-primary {
		--bs-btn-bg: #3B8130;
		--bs-btn-border-color: #3B8130;
		--bs-btn-hover-bg: #D5E1D3;
		--bs-btn-hover-border-color: #D5E1D3;
	}
	ul.tabNav {
		li {
			border-bottom-color: $green1;
			&.active {
				border-bottom-color: $green;
				color: $green;
			}
			&:hover {
				color: $green;
			}
		}
	}
	.card-header-inline {
		background: $green1;
	}
	ul li::marker {
		color: $green;
	}
	label.color {
		color: $green1;
		display: block;
	}
	.infoRow {
		span i {
			cursor: pointer;
		}
		span:hover i {
			color: $green;
		}
	}
	div.multiselect.isMultiselect.small .multiselect__tags .multiselect__tags-wrap .multiselect__tag {
		background: $green;
		.multiselect__tag-icon {
			top: -2px;
			&:after {
				color: $light;
			}
		}
	}
	div.multiselect {
		.multiselect__select {
			border-color: $green;
		}
		&.multiselect--active {
			.multiselect__select {
				background: $green;
			}
		}
	}
	div.multiselect .multiselect__tags {
		border-color: $green1;
	}
	.dp__main.small .dp__pointer.dp__input {
		color: $green;
		border: 1px solid $green1;
	}
	.dp__main.small svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main.small svg.dp__icon.dp__input_icon.dp__input_icons {
		color: $green;
		border: 1px solid $green;
	}
	hr {
		background: $green1;
	}
	.lineitem.last.ml-auto:hover i {
		color: $green;
	}
	.page-item:not(.disabled) .page-link {
		color: $green;
	}
	.fileList i:hover {
		color: $green;
	}
	b.color {
		color: $green !important;
	}
	.loadFullWrap {
		i {
			color: $green;
		}
	}
	.btn-outline-primary {
		--bs-btn-color: #3B8130;
		--bs-btn-border-color: #3B8130;
		--bs-btn-hover-bg: #3B8130;
		--bs-btn-hover-border-color: #3B8130;
	}
	span.btn-logout:hover {
		i {
			color: $green;
		}
	}
	p.color {
		color: $green1;
	}
	#filter_search {
		border-color: $green;
	}
	.topMainIcons .actionIcon.double {
		background: $green1;
	}
	.topMainIcons .actionIcon.double span.active {
		background: $green;
	}
	.topMainIcons .actionIcon.outter .btn i {
		color: $green;
	}
	div.multiselect .multiselect__select {
		border-color: $green;
	}
	div.multiselect.multiselect--active .multiselect__select {
		background: $green;
		&:before {
			border-color: $light transparent transparent transparent;
		}
	}
	div.multiselect.small .multiselect__single {
		color: $green;
	}
	div.multiselect .multiselect__content-wrapper {
		border-color: $green;
	}
	div.multiselect .multiselect__option--highlight {
		background: $green1;
	}
	div.multiselect .multiselect__select:before {
		border-color: $green transparent transparent transparent;
	}
	div.multiselect .multiselect__tags {
		border-color: $green1;
	}
	div.multiselect.multiselect--active .multiselect__tags {
		border-color: $green;
	}
	hr {
		background-color: $green1;
	}
	.navbarTop .input-group-append .btn {
		border-right-color: $green;
	}
	.lineitem.last.ml-auto:hover i {
		color: $green;
	}
}


