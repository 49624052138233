.projects-item {
	> .card > .card-header {
		border-radius: 0;
		h6 {
			margin-top: 6px;
		}
	}
	.taskWrap, .projectWrap {
		transition: .5s all ease 0s;
		cursor: pointer;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		&:last-child {
			border-bottom: 0;
		}
		&:hover, &.active {
			background: $bgbox;
			.lineitem.l-check {
				i {
					opacity: 1;
				}
			}
		}
		.d-flex {
			width: 100%;
		}
		.lineitem {
			transition: .3s all ease 0s;
			max-width: 100%;
			padding-left: 6px;
			padding-right: 6px;
			&.l-title {
				width: 52%;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				font-weight: 500;
				@include font-size(14, 1.8);
				padding-right: 12px;
			}
			&.l-avatar {
				text-align: center;
				padding-left: 12px;
				padding-right: 12px;
				display: none;
				@media #{$medium}{
					display: block;
				}
			}
			&.l-date {
				&.due {
					color: $red;
					font-weight: 500;
				}
				&.today {
					color: $green;
					font-weight: 500;
				}
			}
			&.l-prio {
				width: 55px;
				text-align: center;
			}
			&.l-state {
				width: 55px;
				text-align: center;
			}
			&.l-hours {
				width: 75px;
			}
			&.l-check {
				i {
					opacity: .4;
				}
				&:hover {
					color: $link;
				}
			}
		}
	}
	.taskDetails, .projectDetails {
		padding-top: 12px;
		@include clear();
		.taskInfos, .projectInfos {
			float: right;
			padding: 12px;
			@media #{$large}{
				padding: 24px;
			}
			ul.infoList {
				li {
					label {
						font-weight: 500;
						@include font-size(14, 1.8);
						width: 100px;
					}
				}
			}
		}
		.taskDesc, .projectDesc {
			padding: 12px;
			@media #{$large}{
				padding: 24px;
			}
			width: 500px;
			max-width: 100%;
		}
	}
}
.repeatForm{
	> .card{
		border-radius: 0;
		border: 0;
		.card-header{
			border-radius: 0;
			text-transform: capitalize;
		}
	}
}
.ck.ck-editor {
	margin-bottom: 12px;
}
.firstToUpper{
	text-transform: capitalize;
}
