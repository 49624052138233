@media print {
	#sidenav-main{
		display: none;
	}
	aside.sidenav ~ main.main-content{
		margin-left: 0;
	}
	.card{
		// avoid pagebreak
		page-break-inside: avoid;
	}
}
