.employeeList, .inDocument {
	.employeeItem {
		padding-right: 12px;
		.employeeInfoBar {
			margin-left: 0;
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;
			display: flex;
			flex-wrap: nowrap;
			width: 100%;
			cursor: pointer;
			border-bottom: 1px solid $border;
			&:hover {
				background: $bgbox;
				.arrow {
					color: $blue;
				}
				.price {
					color: $black;
				}
			}
			li {
				&.arrow {
					width: 24px;
					text-align: center;
					@include font-size(16, 1.5);
					margin-right: 12px;
				}
				&.title {
					width: 250px;
				}
				&.quantity {
					margin-left: 12px;
					text-align: center;
				}
				&.price {
					margin-left: auto;
					text-align: left;
					font-weight: bold;
					color: $dark;
				}
				&.unit {
					margin-left: 6px;
					@include font-size(12, 2);
				}
				input {
					height: 20px;
				}
			}
		}
		.groupTimes{
			.day{
				@include clear();
				margin-bottom: 12px;
			}
		}
	}
}

.inDocument {
	.input-group {
		padding-right: 16px;
		input.fullPriceRender {
			height: 20px;
			font-size: 16px;
			margin-left: auto;
			font-weight: bold;
			text-align: right;
		}
		select {
			height: 20px;
			width: 100%;
			font-size: 14px;
		}
	}
	.employeeItem {
		ul.employeeInfoBar {
			li {
				padding-top: 6px;
				padding-left: 3px;
				padding-right: 3px;
				input {
					max-width: 100%;
					width: 100%;
					height: 20px;
					font-size: 14px;
				}
				&.quantity {
					width: 90px;
				}
				&.unit {
					width: 100px;
				}
				&.title {
					width: 400px;
				}
				&.final_price {
					input {
						text-align: right;
					}
				}
			}
		}
	}
}

.employee-list {
	> .card {
		border-radius: 0;
	}
	.employee-item{
		&.headline {
			.productLineWrap:hover {
				background: transparent;
			}
			.productLineWrap {
				border-left-color: $light;
				* {
					color: $grey;
				}
			}
			.lineitem {
				color: $grey;
				&.first{
					padding-top: 0 !important;
				}
				> span + i {
					margin-left: 6px;
					color: $blue;
				}
				span {
					cursor: pointer;
					color: $grey;
					@include font-size(14);
				}
				span.active {
					color: $blue;
					font-weight: 500;
				}
			}
		}
	}

		.employeeLineWrap {
			padding-top: 12px;
			transition: .5s all ease 0s;
			padding-bottom: 9px;
			cursor: pointer;
			display: flex;
			align-items: center;
			width: 100%;
			border-bottom: 1px solid $border;

			&:hover, &.active {
				background: $bgbox;
			}

			.lineitem {
				transition: .3s all ease 0s;
				max-width: 100%;
				padding-left: 6px;
				padding-right: 6px;


				&.l-checkbox {
					padding-left: 12px;
					padding-top: 3px;
					padding-right: 6px;
					min-width: 51px;
					.checkbox {
						width: 20px;
					}
				}

				&.l-category {
					width: 170px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					@include font-size(14, 1.8);
					display: none;
					@media #{$hd}{
						display: block;
					}
				}

				&.l-avatar {
					text-align: center;
					padding-left: 32px;
					padding-right: 12px;
				}

				&.l-number {
					padding-left: 12px;
					padding-right: 12px;
					width: 105px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					text-align: center;
				}

				&.l-title {
					width: 320px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					font-weight: 500;
					@include font-size(13, 1.8);
					padding-right: 12px;

					&.l-doctitle {
						width: 440px;
					}
				}

				&.l-phone {
					display: none;
					@media #{$xlarge}{
						display: block;
					}
				}

				&.l-email {
					width: 260px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					@include font-size(13, 1.8);
					display: none;
					@media #{$large}{
						display: block;
					}

					button {
						white-space: nowrap;
						text-overflow: ellipsis;;
						overflow: hidden;
						max-width: 100%;
					}
				}

				&.l-price {
					width: 100px;
					text-align: right;
					padding-right: 12px;
				}

				&.l-prio {
					padding-right: 24px;
					padding-left: 12px;
				}

				&.l-unit {
					text-align: left;
					width: 100px;

					.badge {
						width: 100%;
					}
				}

				&.l-action {
					align-self: flex-end;
					padding-right: 24px;
					padding-left: 24px;
					text-align: center;

					.fa-x {
						display: inline-block;
					}
				}

				&.last {
					padding-right: 16px;
				}
			}
		}

		> .card {
			box-shadow: 0px 3px 6px #00000029;
			margin-bottom: 24px;
			border-radius: $br;

			.employeeLineWrap {
				background: $bgbox;

				.fa-x {
					display: inline-block;
				}
			}

			.card {
				@include clear();
				border: 0;
				border-top: 0;
				margin-bottom: 12px;

				.card-body {
					border: 0;
					border-radius: 0 0 $br $br;
					border-top: 0;
					margin-bottom: 12px;
					width: 100%;
				}
			}

			.employee-list.card-body > .employeeItem.activeEmployee {
				box-shadow: 0px 3px 6px #00000029;
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 24px;
				border-radius: 0 0 $br $br;

				.employeeLineWrap {
					background: $bgbox;

					.fa-x {
						display: inline-block;
					}
				}

				.card {
					@include clear();
					border: 0;
					border-top: 0;
					margin-bottom: 12px;

					.card-header {
						border-radius: 0;
					}

					.card-body {
						border: 0;
						border-top: 0;
						margin-bottom: 0;
						width: 100%;
					}
				}

				.productContent {
					> .card {
						> .card-header {
							border-radius: 0;
						}
					}
				}
			}

			.employeeContent {
				> .card {
					> .card-header {
						border-radius: 0;
					}
				}
			}
		}
	}

