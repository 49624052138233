
.draft-list{
	.draft-item{
		&.headline {
			.draftLineWrap:hover {
				background: transparent;
			}
			.draftLineWrap {
				border-left-color: $light;
				* {
					color: $grey;
				}
			}
			.lineitem {
				> span + i {
					margin-left: 6px;
					color: $blue;
				}
				span {
					cursor: pointer;
					@include font-size(14);
				}
				span.active {
					color: $blue;
					font-weight: 500;
				}
			}
		}
	}
}
.draftList, .inDocument {
	.draftItem {
		&.headline {
			.documentLineWrap:hover {
				background: transparent;
			}
			.documentLineWrap {
				border-left-color: $light;
				* {
					color: $grey;
				}
			}
			.lineitem {
				> span + i {
					margin-left: 6px;
					color: $blue;
				}
				span {
					cursor: pointer;
					@include font-size(14);
				}
				span.active {
					color: $blue;
					font-weight: 500;
				}
			}
		}

		padding-right: 12px;
		.draftInfoBar {
			margin-left: 0;
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;
			display: flex;
			flex-wrap: nowrap;
			width: 100%;
			border-bottom: 1px solid $border;
			&:hover {
				background: $bgbox;
				.arrow {
					color: $blue;
				}
				.price {
					color: $black;
				}
			}
			li {
				&.arrow {
					width: 24px;
					text-align: center;
					@include font-size(16, 1.5);
					margin-right: 12px;
					cursor: pointer;
				}
				&.title {
					width: 250px;
					cursor: pointer;
				}
				&.quantity {
					margin-left: 12px;
					text-align: center;
				}
				&.price {
					margin-left: auto;
					text-align: left;
					font-weight: bold;
					color: $dark;
				}
				&.unit {
					margin-left: 6px;
					@include font-size(12, 2);
				}
				input {
					height: 20px;
				}
			}
		}
	}
}

.inDocument {
	.input-group {
		padding-right: 16px;
		input.fullPriceRender {
			height: 20px;
			font-size: 16px;
			margin-left: auto;
			font-weight: bold;
			text-align: right;
		}
		select{
			height: 20px;
			width: 100%;
			font-size: 14px;
		}
	}
	.draftItem {
		ul.draftInfoBar {
			li {
				padding-top: 6px;
				padding-left: 3px;
				padding-right: 3px;
				input {
					max-width: 100%;
					width: 100%;
					height: 20px;
					font-size: 14px;
				}
				&.quantity {
					width: 90px;
				}
				&.unit {
					width: 100px;
				}
				&.title {
					width: 400px;
				}
				&.final_price {
					input {
						text-align: right;
					}
				}
			}
		}
	}
}
.appView.drafts {

	.draftLineWrap {
		transition: .5s all ease 0s;
		display: flex;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid $border;
		&:hover, &.active {
			background: $bgbox;
		}
		.lineitem {
			transition: .3s all ease 0s;
			padding-top: 8px;
			padding-bottom: 9px;
			padding-left: 6px;
			padding-right: 6px;
			min-width: 32px;
			&.l-checkbox {
				margin-right: 10px;
				padding-left: 12px;
				padding-right: 6px;
			}
			&.l-avatar {
				text-align: center;
				padding-left: 32px;
				padding-right: 12px;
			}
			&.l-number {
				padding-left: 12px;
				padding-right: 12px;
				width: 105px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: center;
			}
			&.l-title {
				width: 220px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				max-width: 100%;
				@include font-size(14, 1.8);
				@media #{$large}{
					width: 520px;
				}
				&.l-doctitle {
					width: 600px;
				}
			}


			&.l-email {
				width: 200px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				@include font-size(14, 1.8);
				button {
					white-space: nowrap;
					text-overflow: ellipsis;;
					overflow: hidden;
					max-width: 100%;
				}
			}
			&.l-price {
				width: 100px;
				text-align: right;
				padding-right: 12px;
			}

			&.l-id {
				width: 10%;
				text-align: center;
			}

			&.l-color {
				width: 80px;
				text-align: center;
				padding-right: 12px;
			}

			&.l-colorcountry {
				width: 180px;
				text-align: center;
				padding-right: 12px;
				margin-right: 20px;
			}

			&.l-icon {
				 width: 30%;
				 text-align: center;
				 padding-right: px;
			 }

			&.l-default {
				width: 30%;
				text-align: center;
				padding-right: px;
			}

			&.l-prio {
				padding-right: 24px;
				padding-left: 12px;
			}
			&.l-unit {
				text-align: left;
				width: 100px;
				.badge {
					width: 100%;
				}
			}
			&.l-action{
				align-self: flex-end;
				padding-right: 24px;
				padding-left: 24px;
				text-align: center;
				cursor: pointer;
				.fa-x {
					display: inline-block;
				}

			}
			&.last {
				padding-right: 16px;

				cursor: pointer;
			}
		}
	}
	.draft-list.card-body > .draftItem.activeDraft {
		box-shadow: 0px 3px 6px #00000029;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 24px;

		.draftLineWrap {
			background: $bgbox;

			.fa-x {
				display: inline-block;
			}
		}
		  .card  {
			@include clear();
			border: 0;
			border-top: 0;
			margin-bottom: 12px;
			.card-body {
				border: 0;
				border-radius: 0 0 $br $br;
				border-top: 0;
				margin-bottom: 12px;
				width: 100%;
			}
		}
		.draftContent {
			> .card {
				> .card-header {
					border-radius: 0;
				}
			}
		}
	}
}
